.usaha-detail{

    .top-image{
        width: 100%;
        max-height: 40vh;
        position: relative;
        img{
            object-fit: cover;
            z-index: 9;
            border-radius: 0;
        }

        .ribbon{
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 10;
        }

        .ribbon-group{
          position: absolute;
          display: flex;
          top: 10px;
          left: 10px;
          z-index: 10;

          .ribbon-group-children{
            position: relative;
            margin-right: 5px;
          }
        }


        .top-slider-wrapper{
            position: relative;
            height: 40vh;
            .product-slider{
                .owl-carousel{
                    position: static;
                    height: 100%;
                    .owl-stage-outer{
                        height: 100%;
                    }
                }
                .owl-theme .owl-dots{
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 30px;
                }
            }
        }
    }

    .summary-info{
        .row{
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
        .title{
            display: block;
            width: 100%;
            font-weight: $font-style-regular;
            color: $dark-grey;
        }

        .value{
            font-size: 12px;
            font-weight: $font-style-semi-bold;
            display: block;
        }
    }

    >.card{
        position: relative;
        z-index: 11;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        margin-top: -20px;
        border-bottom: 0;
    }
}

.insurance-product-logo {
  display: inline;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.usaha-detail .top-image .insurance-product-logo .ribbon {
  position: inherit;
  top: 10px;
  left: 10px;
  z-index: 10;
}

.progress{
    border-radius: 50px;
    .progress-bar{
        background-color: $light-green;
        border-radius: 50px;
        &.orange{
            background-color: $orange;
        }

        &.brand-color{
            background-color: $brand-color;
        }
    }
}


.business{
    .business-infos{
        margin-top: 10px;
        .item{
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            [class^="icon-"]{
                font-size: 14px;
                width: 25px;
                display: inline-block;
                &:before{
                    color: $dark-grey;
                }
            }

            .title{

                font-weight: $font-style-regular;
                font-size: 12px;
                color: $dark-grey;
                display: inline-block;
                margin-right: 10px;
            }

            .value{
                font-weight: $font-style-bold;
                font-size: 12px;
            }
        }
    }
}

.business-detail{

    background: $light-grey;
    @extend .full-screen;
    .menu{
        .col-3{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            text-align: center;
            .button-label{
                margin-top: 15px;
            }
        }
        .square-button{
            width: 62px;
            height: 62px;
            display: flex;
            align-items: center;
            justify-content: center;
            [class^='icon-']{
                font-size: 22px;
                &:before{
                    color: $brand-color;
                }
            }

            &:hover, &.active{
                @include ramerame-bg-gradient();
                [class^='icon-']{
                    &:before{
                        color: $white;
                    }
                }
            }
        }
    }

    .content{
        padding-top: 1rem;
    }

    >div.content.grey-background{
        background: $light-grey;
        .ticket{
            padding: 16px;
            margin-top: 1rem;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
            font-size: 12px;
            p:last-child{
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        .timeline{
            padding-bottom: 20px;
            .month{
                .dates{
                    .date{
                        display: flex;
                        margin-bottom: 16px;
                        .big-date{
                            margin-right: 16px;
                            padding-top: 6px;
                        }
                        .status-update{
                            background-color: $white;
                            padding: 1rem;
                            flex-grow:1;
                            border-radius: 6px;
                        }
                    }
                }
            }
        }
    }

    .business-image{
        padding-top: 24px;
        padding-bottom: 1rem;
    }
    .menu{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    >div{
        background: $white;
        padding-left: 16px;
        padding-right: 16px;
    }
}
