.form-group{
    label{
        font-weight: $font-style-bold;
        font-size: 14px;
        line-height: 24px;
        color: $form-label-color;
    }
    .radio-group{
        display:flex;
        flex-direction: column;
    }
    .form-control{
        border: 1px solid $grey;
        background: $white;

        outline-color: $brand-color;
        font-family: $font-family;
        position: relative;
        z-index: 9;

        &::placeholder{
            color: $form-placeholder-color !important;
            font-family: $font-family !important;
        }

        &:disabled{
            background-color: $disabled-bg-color;
            color: $disabled-color;
        }
    }

    .form-text{
        font-family: $font-family;
        color: $form-helper-color;
        margin-top: 10px;

    }
    &.has-error{
        input, select{
            border-color: $form-error-color;
        }

        .form-text{
            color: $form-error-color;
        }
    }

    // dropzone for ktp upload
    .dropzone{
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        border: 2px dashed $grey;
        background: $white;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .helper{
        font-weight: $font-style-regular;
        ul{
            margin: 0;
            padding-left: 20px;
        }
    }
}

.filter-panel{
    display:flex;
    align-items:center;
    justify-content: space-between;
    &.scrollable{
        overflow: auto;
        white-space: nowrap;
        display: block;
        flex-wrap: nowrap;
        .filter{
            @extend .secondary-button;
            @extend .small-button;
            font-weight: $font-style-bold;
            .mat-button-wrapper{
                font-weight: $font-style-bold;
            }
            margin-right: 1rem;

            &:hover, &.active{
                border-color: $brand-color;
                @include ramerame-bg-gradient('true');
                .mat-button-wrapper{
                    color: $white !important;
                }
            }
        }
    }
}

.input-group{
    &.has-button-append{
        .form-control{
            border-radius: 6px;
        }
        .input-group-append{
            border: none;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
        }
    }
}

.pagination{
    justify-content: center;
    .page-item{
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        a, button{
            font-size: 16px;
            line-height: 21px;
            font-weight: $font-style-regular;
            color: $form-label-color;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        &.active{
            a, button{
                background: $brand-color;
                color: $white;
                border-color: $brand-color;
            }
        }
    }
}

.grey-separator{
    height: 10px;
    background: $light-grey;
    width: calc(100% + 32px);
    position: relative;
    left: -16px;
    right: -16px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.form-separator{
    border-top: 1px solid $form-separator-color;
    width: 100%;
    min-height: 1px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .middle-text{
        position: relative;
        background: $white;
        padding-left: 15px;
        padding-right: 15px;
        top: -10px;
    }
}

.table{
  &.fixed{
    table-layout: fixed;
  }
}


// general style
.download-button, .primary-button, .secondary-button, .clear-button, button{
    border: none;
    border-radius: 6px;
    transition: all 0.5s;
    &.mat-flat-button, &.mat-button{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        border-radius: 6px;
        &.big-button{
            height: 50px;
            width: 100%;
            align-items: center;
            justify-content: center;
        }

        &:disabled{
            background: $disabled-bg-color;
            .mat-button-wrapper{
                color: $disabled-color;
            }
        }

        .mat-button-wrapper{
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &.small-button{
            display: inline-flex;
            padding: 5px 10px;
            height: 30px;
            justify-content: center;
            align-items: center;
        }

    }
    &.big-button{
        height: 50px;
        display: inline-flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        &.inline{
            width: auto;
            padding-left: 18px;
            padding-right: 18px;
        }
    }

    &:disabled{
        background: $disabled-bg-color;
        color: $disabled-color;
        .mat-button-wrapper{
            color: $disabled-color;
        }
        [class^="icon-"]{
            &:hover{
                &:before{
                    color: $disabled-color !important;
                }
            }
        }
    }
}


.primary-button {
    @include ramerame-bg-gradient();
    font-family: $font-family;
    color: $white;
    .mat-button-wrapper{
        color: $white;
    }
    span {
        color: $white;
    }
}

.download-button{
    border: 1px solid $green3 !important;
    font-family: $font-family;
    color: $green3;
    background: $white;
    .mat-button-wrapper{
        color: $green3;
    }
    span {
        color: $green3;
    }
}

.download-button-inactive{
  border: 1px solid $dark-grey !important;
  font-family: $font-family;
  color: $dark-grey;
  background: $white;
  .mat-button-wrapper{
    color: $dark-grey;
  }
  span {
    color: $dark-grey;
  }
}

.grey-button, .grey-button.mat-flat-button{
    background: $white;
    border: 1px solid $dark-grey;
    color: $dark-grey;
    .mat-button-wrapper{
        color: $dark-grey;
    }

    span{
        color: $dark-grey;
        &:before{
            color: $dark-grey;
        }
    }

    &:hover{
        background: $brand-color;
        color: $white;
        border-color: $brand-color;
        .mat-button-wrapper{
            color: $white;
        }
        span{
            color: $white;
        }
        [class^='icon-']:before{
            color: $white;
        }
    }
}

.secondary-button, .secondary-button.mat-flat-button{
    background: $white;
    border: 1px solid $brand-color;
    color: $brand-color;
    .mat-button-wrapper{
        color: $brand-color;
    }

    span{
        color: $brand-color;
        &:after{
            color: $brand-color;
        }
    }

    &.invert{
        background: $white;
        border: 1px solid $white;
        color: $brand-color;
    }

    &:hover{
        background: $brand-color;
        color: $white;
        .mat-button-wrapper{
            color: $white;
        }
        span{
            color: $white;
            &:after{
                color: $brand-color;
            }
        }
        [class^='icon-']:before{
            color: $white;
        }
    }
}

.btn{
    @include ramerame-bg-gradient();
    font-family: $font-family;
    border: none;
    border-radius: 6px;
    color: $white;
    font-size: 14px;

    height: 50px;
    outline: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:hover{
        color: $white;
        background: $green3;
    }

    &:active, &:focus{
    background: $brand-color;
        color: $white;
    }

    &:disabled{
        background: $disabled-bg-color;
        color: $disabled-color;
    }

    &.btn-small{
        height: 35px;
    }

    &.btn-secondary{
        background: $white;
        border: 1px solid $blue;
        color: $blue;
    }
}
