.ribbon, .ribbon-group-children{
    background: $grey2;
    color: $dark-grey;
    &.orange{
        background: $orange;
        color: $white;
    }
    &.green{
      background: $green4;
      color: $white;
    }
    &.red{
        background: $red;
        color: $white;
    }
    &.brand-color{
      background: $brand-color;
      color: $white;
    }
    &.blue{
      background: $blue;
      color: $white;
    }
    &.insurance-color{
      background: $insurance-color;
      color: $white;
    }

    padding: 10px;
    border-radius: 6px;

    display: inline-block;
    width: auto;

    @include media-breakpoint-down(sm){
        padding: 5px;
        font-size: 10px;
    }

    .icon-aman {
      &:before {
        color: $insurance-color;
        font-size: 18px;
      }
    }
}

.card{
    border-radius: 6px;
    border-color: $grey2;

    .left-image{
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .ribbon{
        position: absolute;
        margin-top: 10px;
        margin-left: 10px;
        z-index: $z-index-form;
        &.right-aligned{
            margin-left: auto;
            right: 10px;
        }
        &.static{
            position: static;
            margin: auto;
        }
    }

      .ribbon-group{
        position: absolute;
        display: flex;
        top: 10px;
        left: 10px;
        z-index: 10;

        .ribbon-group-children{
          position: relative;
          margin-right: 5px;
        }
      }
    &.business{
        height: 100%;
        @include media-breakpoint-down(sm){
            .card-body{
                padding: 0.5rem;
            }
        }
    }
}
