// Font style
/*
    Extra-light 200
    Light       300
    Regular     400
    Semi-bold   600
    Bold        700
    Extra-bold  800
    Black       900
*/
$font-family: "Nunito", sans-serif;
$font-family-chivo: "Chivo", sans-serif;
$font-family-playfair: "Playfair Display", serif;
$font-family-poppins: "Poppins", sans-serif;
$font-style-extra-light: 200;
$font-style-light: 300;
$font-style-regular: 400;
$font-style-semi-bold: 600;
$font-style-bold: 700;
$font-style-extra-bold: 800;
$font-style-black: 900;

.text-weight-bold {
  font-weight: $font-style-bold !important;
}
.text-weight-extra-light {
  font-weight: $font-style-extra-light !important;
}
.text-weight-light {
  font-weight: $font-style-light !important;
}
.text-weight-regular {
  font-weight: $font-style-regular !important;
}
.text-weight-black {
  font-weight: $font-style-black !important;
}
.text-weight-extra-bold {
  font-weight: $font-style-extra-bold !important;
}
.text-weight-semi-bold {
  font-weight: $font-style-semi-bold !important;
}

// color pallete
$black: #000;
$white: #fff;

$brand-color: #195174;
$green: #007794;
$green3: #009d9f;
$green4: #2ec295;
$green5: #96e17f;
$yellow: #f7c30c;
$red: #fe453d;
$light-red: #ffeded;
$insurance-color: #197448;

$light-green: #29d972;
$blue: #009cf8;

$orange: #f78e00;

$light-grey: #f5f5f5;
$light-grey2: #fafafa;
$grey: #c4c4c4;
$grey2: #e7eaee;
$dark-grey: #9fadbd;

$disabled-bg-color: #f5f5f5;
$disabled-color: #999;
$form-border-color: #ccc;
$form-separator-color: #e7eaee;
$form-placeholder-color: #999;
$form-error-color: #ff0000;
$form-label-color: #373a3c;
$form-helper-color: #707070;

// max width for body - content
$max-width-wrapper: 680px;
$max-width-dialog: 660px;

// z-index
$z-index-form: 4;
$z-index-fixed-button: 100;

@mixin ramerame-bg-gradient($important: false) {
  @if $important {
    background: linear-gradient(90deg, #195174 0%, #009d9f 100%) !important;
  }
  @if not $important {
    background: linear-gradient(90deg, #195174 0%, #009d9f 100%);
  }
}

@mixin ramerame-box-shadow() {
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.24);
}

.bg-brand {
  background-color: $brand-color;
}

.bg-brand-gradient {
  @include ramerame-bg-gradient();
}

.text-grey {
  color: $dark-grey;

  &:before {
    color: $dark-grey;
  }
}

.text-orange {
  color: $orange;
  &:before {
    color: $orange;
  }
}

.text-yellow {
  color: $yellow;
  &:before {
    color: $yellow;
  }
}

.text-red,
.text-danger {
  color: $red;
  &:before {
    color: $red;
  }
}

.text-green {
  color: $green3;
  &:before {
    color: $green3;
  }
}

.brand-color,
.text-brand-color {
  color: $brand-color;

  &:before {
    color: $brand-color;
  }
}
