
.black-button{
    &.mat-flat-button{
        border: 1px solid #ccc;
        .mat-button-wrapper{
            color: $black;
            font-weight: $font-style-bold;
            img{
                max-height: 25px;
                margin-right: 20px;
            }
        }
        
        background: $white;
        width: 100%;
        height: 44px;
        &:active, &:focus{
            background: $light-grey2;
        }
    }
}