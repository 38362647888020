
// general style and cleaning material style
  .width-100{
    width: 100%;
  }

  button, small, h1, h2, h3, h4, h5, h6, p, div, span{
    font-family: $font-family;
    outline: none;
  }

  p, div, span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  small{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .small{
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
  }

  button{
    font-family: $font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
  }

  h1, .h1{
    font-size: 48px;
    line-height: 65px;
    font-style: normal;
    font-weight: $font-style-bold;
  }

  h2,.h2{
    font-style: normal;
    font-weight:  $font-style-bold;
    font-size: 36px;
    line-height: 49px;
  }

  h3, .h3{
    font-style: normal;
    font-weight:  $font-style-bold;
    font-size: 24px;
    line-height: 33px;
  }

  h4, .h4{
    font-style: normal;
    font-weight:  $font-style-bold;
    font-size: 18px;
    line-height: 25px;
  }
  h5, .h5{
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
  }
  h6, .h6{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
  }

  a{
    color: $brand-color;
    font-size: 14px;
    line-height: 19px;
    font-family: $font-family;
    text-decoration: none;
    &:hover{
      color: $green3;
      text-decoration: none;
    }
  }

  .grey-background{
    background: $light-grey  !important;
  }

  .white-background{
    background: $white !important;
  }

  html, body { height: 100%; background: $grey; }
  body { margin: 0; font-family: $font-family; font-weight: $font-style-regular; color: $black; -ms-overflow-style: scrollbar;}
  button:focus {
    outline: 0;
  }

  .clear-transparent {
    background: none;
    border: none;
  }

textarea,
input:matches(
  [type="email"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="url"]
) {
  -webkit-appearance: none;
}

.ng-dropdown-panel .scroll-host {
  position: absolute !important;
  z-index: 99999;
  width: 100%;
}

.button-link-white {
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
.margin-top-14px {
  margin-top: 14px;
}
.margin-top-15px {
  margin-top: 15px;
}
.line-height-23px {
  line-height: 23px;
}
.border-zero {
  border: 0;
}
.container-sp {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

