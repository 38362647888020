aside.sidenav{
    .main-logo{
        max-height: 20px;
        margin-left: 5px;
    }
    .toolbar-top{
        background-color: $brand-color;
        position: fixed;
        top: 0;
        z-index: 98;
        max-width: $max-width-wrapper;
        left: 50%;
        transform: translateX(-50%);
        max-height: 56px;

        span {
            color: $white;
        }
        .toolbar-top-container{
            width: 100%;
            max-width: $max-width-wrapper;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .logo{
                img{
                    border: 0;
                    outline: none;
                }
            }
        }
    }

    .padding-top-60 {
        padding-top: 60px;
    }

    .main-body {
        background: $white;
        margin-top: 56px;
        color: $black;
        height: calc(100% - 56px);
    }

    .mat-button-wrapper{
        .mat-icon{
            color: $white;
        }
    }

    .mat-drawer-inner-container{
        position: fixed;
        max-width: 250px;
    }

    .mat-drawer{
        background: $white;
        .mat-list-text p{
            color: $black;
            font-size: 14px;
            font-weight: $font-style-semi-bold;
        }
    }

    .toggler{
        [class^="icon-"], [class*=" icon-"] {
            font-size: 22px;
        }
        .icon-menu, .icon-cart{
            font-size: 18px;
        }

    }
    .mat-drawer-side.mat-drawer-end{
        background: $white;
        -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,0.24);
        -moz-box-shadow: 0 0 20px 0 rgba(0,0,0,0.24);
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.24);
        // @include ramerame-box-shadow();
    }

    .mat-list-item {
        p {
            color: $white;
        }
    }

    .sidenav-container {
        min-height: 100vh;
        max-width: $max-width-wrapper;
        margin:auto;
    }

    .mat-drawer-backdrop.mat-drawer-shown {
        display: none;
    }

    .mat-drawer-container {
        background-color: $white;
    }

    .mat-sidenav {
        width: 250px;
        min-height: 100%;
        background-size: cover;
    }
}



.foot-menu{
    position: fixed;
    bottom: 0;
    z-index: 10;
    height: 60px;
    width: 100%;
    background: $white;

    max-width: 680px;
    left: 50%;
    transform: translateX(-50%);
    @include ramerame-box-shadow();
    ul{
        list-style: none;
        margin:0;
        padding: 0;
        text-align: center;
        height: 100%;
        justify-content: space-around;
        display: flex;
        li{
            padding-left: 5px;
            padding-right: 5px;
            transition: all 0.5s;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            button, a {
                font-weight: $font-style-semi-bold;
                color: $grey;
                text-decoration: none;
            }

            // @include media-breakpoint-down(sm){
            //     width: 20%;
            // }

            [class^="icon-"], [class*=" icon-"] {
                display: block;
                font-size: 22px;
                &:before{
                    color: $grey;
                }
            }

            &.active, &:hover{
                button, a{
                    color: $brand-color;
                }
                [class^="icon-"], [class*=" icon-"] {
                    &:before{
                        color: $brand-color;
                    }
                }
            }

        }
    }
    @media screen and (max-width:500px){
        overflow: auto;
        padding-top: 5px;
        ul{
            display: flex;
            justify-content: space-between;
            padding-left: 16px;
            padding-right: 16px;
            // iphone 5 and 4
            @media screen and (max-width: 340px){
                padding-left: 10px;
                padding-right: 10px;
            }
            li{
                max-width: 20%;

                padding-left: 0;
                padding-right: 0;
                button, a{
                    font-size: 10px;
                    line-height: 12px;
                    [class^="icon-"], [class*=" icon-"] {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}
