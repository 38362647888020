.general-stuff {
  display: flex;
  justify-content: space-between;

  .end-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $green3;
  }
}

.campaign-menu {
  .list-group-item {
    display: flex;
    color: $black;
    justify-content: space-between;
    transition: all 0.4s;
    border-radius: 12px;
    border: 1px solid $grey2;
    box-sizing: border-box;
    align-items: center;
    margin-bottom: 8px;

    [class^='icon'] {
      font-size: 20px;
    }

    &:hover {
      color: $brand-color;
      border-color: $brand-color;

      .icon-chevron-right {
        &:before {
          color: $brand-color;
        }
      }
    }

    .center {
      display: flex;
      align-items: center;
      height: 18px;
      width: 100%;
      margin-left: 14px;
      left: 2px;
      top: 3px;
      border-radius: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;

      label {
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0 12px;
      }
    }

    .right {
      font-size: 12px;
    }
  }
}

.category-menu-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  flex: none;
  flex-grow: 0;
  margin: 16px 0;

  .icon-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: none;
    flex-grow: 1;
    width: 73.75px;
    text-align: center;

    [class^="icon"] {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border: 1px solid $grey2;
      box-sizing: border-box;
      border-radius: 12px;
      margin-bottom: 8px;
      font-size: 20px;
    }

    .label {
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      flex: none;
      align-self: stretch;
      flex-grow: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.sliding-campaigns {
  overflow: auto;
  flex-wrap: nowrap;

  .business-infos {
    background: $white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    width: 100%;
    overflow: auto;

    .image-top {
      left: 0;
      top: 0;
      object-fit: fill;
    }

    .body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 12px;

      .title-name {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $black;
        margin-bottom: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
      }

      .icon-coin::before {
        color: #fff;
      }

      .point-chip {
        background: #F7CF00;
        color: #fff;
        border-radius: 10px;
        padding: 3px 6px;
        width: fit-content;
        align-items: end;
        font-size: 11px;
      }

      .title-merchant {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $dark-grey;
        margin-bottom: 4px;
      }

      .title-point {
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 19px;
        color: $green3;
        margin-bottom: 4px;
      }

      .title-point-strike {
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 19px;
        color: $grey;
        margin-bottom: 4px;
      }


    }
    .highlight-ribbon {
      position: absolute;
      background: #E0A712;
      color: #fff;
      font-weight: bold;
      font-size: 11px;
      padding: 4px 6px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.campaign-category {
  .nopadding {
    padding: 0 !important;
    margin: 0 !important;
  }

  .body-detail {
    top: 24px;
    background: $white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    .left-image {
      width: 100%;
      height: auto;
      left: 0;
      top: 0;
      border-radius: 12px;
    }

    .body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 12px 12px;

      .title-name {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $black;
        margin-bottom: 4px;
      }

      .title-merchant {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $dark-grey;
        margin-bottom: 4px;
      }

      .title-point {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: $green3;
        margin-bottom: 4px;
      }

      .point-chip {
        background: #F7CF00;
        border-radius: 12px;
        color: #fff;
        display: block;
        font-size: 11px;
      }

      .title-point-strike {
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 19px;
        color: $grey;
        margin-bottom: 4px;
      }


    }
    .highlight-ribbon {
      position: absolute;
      background: #E0A712;
      color: #fff;
      font-weight: bold;
      font-size: 11px;
      padding: 4px 6px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.my-voucher {
  .nopadding {
    padding: 0 !important;
    margin: 0 !important;
  }
  .with-label span{
    padding: 5px !important;
    height: auto !important;
    font-size: 12px !important;
    margin: 3px !important;
  }
  .c-body-detail {
    border: 1px solid $grey2;
    box-sizing: border-box;
    box-shadow: 0px 6px 12px rgba(159, 173, 189, 0.15);
    border-radius: 8px;

    .left-image {
      object-fit: cover; /* Do not scale the image */
      object-position: center; /* Center the image within the element */
      width: 64px;
      height: 64px;
      margin: 16px;
      border-radius: 6px;
    }

    .i-claim {
      opacity: 0.5;
    }

    .mc-riboon {
      position: absolute;
      right: -1px;
      margin-right: 21px;
      z-index: 4;
      border-radius: 20px;
      font-weight: bold;
      font-size: 12px;
      display: inline-block;
      color: $dark-grey;
      background: $grey2;
      @include media-breakpoint-down(sm) {
        padding: 5px;
      }
    }

    .title-merchant {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: $black;
      margin-bottom: 4px;
    }

    .point-chip {
      background: #F7CF00;
      border-radius: 12px;
      color: #fff;
      display: block;
      font-size: 11px;
    }

    .title-point-strike {
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 19px;
      color: $grey;
      margin-bottom: 4px;
    }

    .highlight-ribbon {
      position: absolute;
      background: #E0A712;
      color: #fff;
      font-weight: bold;
      font-size: 11px;
      padding: 4px 6px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .title-campaign {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: $green3;
      margin-bottom: 4px;
    }

    .title-expired {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $black;
      margin-top: 7px;
      margin-bottom: 4px;
    }

    .title-campaign-claimed {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: $dark-grey;
      margin-bottom: 4px;
    }
  }
}

.voucher-detail {
  margin-bottom: 20%;

  .with-label span{
    padding: 5px !important;
    height: auto !important;
    font-size: 12px !important;
    margin: 3px !important;
  }

  .used-btn {
    font-size: 12px;
    background: $green3;
    border-radius: 50px;
    margin-bottom: 16px;
    color: $white;
  }

  .vd-title {
    font-weight: bold;
    font-size: 18px;
    color: $black;
  }

  .vd-title-dtl {
    font-style: normal;
    font-size: 12px;
  }

  .vd-title-point {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
  }

  .tnc {
    font-weight: bold;
    font-size: 14px;
    color: $black;
  }
}

.mc-btn-inactive {
  border-radius: 12px !important;
  padding: 0 10px;
  height: 35px !important;

  .mat-button-wrapper {
    color: $black;
    font-weight: bold;
  }
}

.mc-btn-active {
  background: $green3;
  border-radius: 12px !important;
  padding: 0 10px;
  height: 35px !important;

  .mat-button-wrapper {
    color: $white;
    font-weight: bold;
  }
}

.merchant-outlet {
  padding-bottom: 50px;

  zxing-scanner {
    video {
      height: 444px;
      border-radius: 12px;
    }
  }
  .outlet-header {
    padding-top: 12px;
    padding-left: 8px;
    padding-bottom: 26px;
  }

  .outlet-claim {
    border: 1px solid $grey2;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .outlet-text-claim {
    padding : 24px;
  }

  .outlet-title {
    padding-top: 31px;
    padding-bottom: 11px;
  }

  .outlet-detail {
    border: 1px solid $grey2;
    box-sizing: border-box;
    box-shadow: 0px 6px 12px rgba(159, 173, 189, 0.15);
    border-radius: 8px;
    padding: 16px 6px;
  }

  .outlet-icon-v {
    object-fit: cover;
    object-position: center;
    width: 20px;
    height: 18px;
  }

  .title-campaign {
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .title-expired {
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .outlet-button-scan {
    position: fixed;
    bottom: 45px;
    right: 20px;
  }

  .outlet-text-center {
    text-align: center;
  }
}

.timer {
  letter-spacing: -1px;
}

.timer span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: black;
  background: $grey2;
  border-radius: 4px;
  text-align: center;
  margin: 4px;
  padding: 4px;
  display: inline-flex;
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}

.with-label span {
  letter-spacing: -1px;
  display: flex;
  color: black;
  flex-grow: 1;
  width: 33%;
  background: $grey2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  height: 74px;
  font-size: 14px;
  font-weight: bold;
  position: static;
  margin: 12px;
  text-align: center;
}

.dialog-box-custom-size {
  font-size: 17px !important;
}

.title-point-strike {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 19px;
  color: $grey;
  margin-bottom: 4px;
}

.highlight-ribbon-x {
  position: absolute;
  background: #E0A712;
  color: #fff;
  font-weight: bold;
  font-size: 11px;
  padding: 4px 6px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 99999;
  left: 0;
  top: 0;
}

.search-voucher-btn {
  border: 2px solid #DAE3EC;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px;
  font-size: 16px;
  color: #A2A3A4;
}



