.dialog-container-custom .mat-dialog-container {
  padding: 0;
  height: auto !important;
  border-radius: 12px !important;

  .mat-dialog-content {
    margin: 15px 0 15px !important;
  }

  .clear-transparent {
    position: absolute;
    z-index: 1;
    right: 0;
    margin: 10px;
    filter: drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.25));
  }

  .mat-dialog-actions {
    margin: 0 24px 15px;
    min-height: unset;
  }
}

.fixActionRow {
  height: 100%;
  display: flex;
  flex-direction: column;
  .selectedInsurance {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 119, 148, 0) 0.01%, rgba(0, 119, 148, 0.16) 100%), #FFFFFF;
  }
  .unselectedInsurance {
    border: 1px solid #F5F5F5;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;
    .icon-file-pdf {
      font-size: x-large;
      margin: auto;
      color: #990000;
      float: right;
    }
  }

  .with-insurance {
    padding: 0 !important;
    flex: 0;
  }

  .headTableInsurance {
    height: 20px;
    width: 100%;
    .headTableInsuranceImg {
      width: 70px;
      img {
        border-radius: 6px;
      }
    }
  }

  .insurance-logo {
    img {
      display: block;
      background-size: cover;
      max-width: 15%;
      border-radius: 5px;
    }
    h4 {
      margin-top: 5px;
      margin-left: 10px;
    }
  }
}

.spacer {
  flex-grow: 1;
}

.mat-dialog-container {
  background: $white;
  color: $black;
  max-height: initial;
  width: 95vw !important;
  height: calc(95vh - 150px) !important;

  max-width: $max-width-dialog;

  .close-button {
    position: relative;
    text-align: right;
    top: -20px;
    margin-top: 15px;

    button {
      min-width: auto;
      padding: 0;
      font-size: 24px;

      &:hover, &:active {
        .icon-close:before {
          color: $brand-color;
        }
      }
    }
  }

  .top-image {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    img {
      max-width: 80%;
      @include media-breakpoint-up(md) {
        max-width: 55%;
      }
    }
  }

  .mat-dialog-actions {
    margin-bottom: auto;
    min-height: unset;
  }

  .mat-dialog-content {
    max-height: initial !important;
  }

  .mat-h5, .mat-typography h5 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-style: normal;
  }
}

.cdk-overlay-pane {
  max-width: 100% !important;
}

.error-dialog .mat-dialog-container {
  height: auto !important;

  .title {
    font-weight: $font-style-bold;
    font-family: $font-family;
  }

  .content {
    text-align: center;
  }

  .spacer {
    display: none;
  }
}