.point-exchange {
  .my-point {
    background: #F5F5F5;
    border-radius: 12px;
    padding: 12px 24px;

    .point-label {
      font-size: 16px;
    }
    .point-value {
      font-size: 18px;
      font-weight: bold;
      line-height: 25px;
    }
    .icon-coin {
      margin: 0;
      font-size: 24px;
    }

  }

  .buttons {
    margin: 0;
    padding: 0;
    list-style: none;
    .icon-wrapper {
      margin-right: 18px;
      width: 68px;
    }
    li {
      display: inline-block;
      i {
        font-size: 24px;
        border: 1px solid #E7EAEE;
        padding: 8px;
        border-radius: 12px;
      }
      h6 {
        font-size: 10px;
        margin-top: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .all::before {
    color: #009D9F;
  }

  .mat-slider {
    width: 100%;
  }

  .mat-slider.mat-slider-horizontal .mat-slider-wrapper {
    top: 18px;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
    height: 12px;
    border-radius: 10px
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-background,
  .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    height: 100%;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    background: linear-gradient(180deg, #195174 0%, #007794 58.85%, #009D9F 100%);
  }
  .mat-accent .mat-slider-thumb {
    height: 30px;
    width: 30px;
    background-color: white;
    border: solid 2px gray;
    bottom: -20px;
    right: -20px;
  }
  .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    background-color: white;
  }

  .mat-slider-track-background {
    background: linear-gradient(180deg, #7E8080 -137.49%, rgba(126, 127, 128, 0) 137.49%);
  }

  .card {
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    img {
      border-radius: 12px;
    }
    h2 {
      font-size: 14px;
      font-weight: bold;
      line-height: inherit;
      margin: 12px 12px 0 12px;
    }
    h4 {
      color: #9FADBD;
      margin-left: 12px;
      margin-right: 12px;
      font-size: 12px;
      font-weight: lighter;
    }
    h5 {
      color: #009D9F;
      font-size: 14px;
      font-weight: bold;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

}
