
.cart-panel {
  .cart-co {
    /* position: fixed;
    z-index: 99999;
    bottom: 0; */
    width: 100%;
    max-width: $max-width-wrapper;
    align-items: center;
    /* display:flex; */
    background: $white;
    padding: 15px;
    margin-left: 0;
    margin-right: 0;
    @include ramerame-box-shadow();

    .use-emoney {
      background: $light-grey2;
      border: 1px solid $light-grey;
      margin-bottom: 10px;
      justify-content: space-between;
      align-items: center;
      border-radius: 15px;
      display: flex;
      padding-top: 10px;
      padding-bottom: 10px;

      .form-group {
        margin-bottom: 0;

        label {
          margin-bottom: 0;
        }
      }

      .saldo {
        font-weight: $font-style-bold;
      }
    }
  }

  .cart {
    &.invoice {
      .item {
        &:last-child {
          margin-bottom: 15px;
        }

        .description {
          padding-left: 0;
        }
      }

      .order-list {
        .item {
          border-bottom: 1px solid $grey2;
          margin-bottom: 0;

          &:last-child {
            border-bottom: 0;
          }
        }

        .item:last-child {
          margin-bottom: 0;
        }
      }

      .download-invoice {
        @include ramerame-box-shadow();

        .form-separator {

          margin-left: -15px;
          margin-right: -15px;
          width: calc(100% + 30px);
        }

        .item:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.confirm {
      .form-separator {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
      }

      .summary {
        margin-bottom: 0;
        padding-bottom: 15px;
      }

      .item:last-child {
        margin-bottom: 0;
        @include ramerame-box-shadow();
      }
    }

    .item {
      display: flex;
      background: $white;
      padding: 15px;
      //margin-bottom: 15px;
      margin-left: 0;
      margin-right: 0;

      &:last-child {
        margin-bottom: 10px;
      }

      .image {
        padding-right: 7.5px;
        padding-left: 0;
      }

      .description {
        padding-right: 0;
        padding-left: 7.5px;
      }

      .actions {
        display: flex;
        justify-content: flex-end;

        [class^="icon-"] {
          font-size: 22px;

          &:before {
            color: $disabled-color;
          }

          &:hover {
            &:before {
              color: $brand-color;
            }
          }
        }

        .qty-wrapper {
          display: flex;

          button {
            padding-left: 5px;
            padding-right: 5px;
            min-width: auto;
          }

          .qty {
            font-size: 14px;
            font-family: $font-family;
            border: none;
            border-bottom: 1px solid $grey2;
            max-width: 40px;
            background: none;
            box-shadow: none;
            outline: none;
            font-weight: $font-style-bold;
          }
        }

      }
    }
    .bg-gradient {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 119, 148, 0) 0.01%, rgba(0, 119, 148, 0.16) 200%), #FFFFFF !important;
    }
  }

  .insurance-chk {
    padding-top: 3px;
    padding-left: initial;
    padding-right: initial;
  }

  .insurance-opt {
    background: #FAFAFA;
    border: 1px solid #F5F5F5;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
  }

  .insurance-info {
    vertical-align: middle;
    margin-left: 5px;
  }

  .with-insurance {
  }

  .change-insurance {
    margin-top: 5px;
    margin-left: 10px;
  }
}
